import React from "react";
import theme from "theme";
import { Theme, Text, Box, Section, Image } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"about"} />
		<Helmet>
			<title>
				Hakkında | Kartepe ATV Safari - Sizin Maceranız, Bizim Uzmanlığımız
			</title>
			<meta name={"description"} content={"Macera ihtiyaçlarınıza göre uyarlanmış kapsamlı off-road kiralama hizmetleri"} />
			<meta property={"og:title"} content={"Hakkında | Kartepe ATV Safari - Sizin Maceranız, Bizim Uzmanlığımız"} />
			<meta property={"og:description"} content={"Macera ihtiyaçlarınıza göre uyarlanmış kapsamlı off-road kiralama hizmetleri"} />
			<meta property={"og:image"} content={"https://novaverolip.com/images/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://novaverolip.com/images/108754334.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://novaverolip.com/images/108754334.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://novaverolip.com/images/108754334.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://novaverolip.com/images/108754334.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://novaverolip.com/images/108754334.png"} />
			<meta name={"msapplication-TileImage"} content={"https://novaverolip.com/images/108754334.png"} />
			<meta name={"msapplication-TileColor"} content={"https://novaverolip.com/images/108754334.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="140px 0" sm-padding="40px 0 40px 0" background="url(https://novaverolip.com/images/6.jpg) center/cover">
			<Override slot="SectionContent" sm-align-items="center" />
			<Box max-width="560px" padding="50px 80px 80px 50px" background="--color-light" color="--dark">
				<Text
					as="h4"
					font="--base"
					color="--grey"
					letter-spacing="1px"
					text-transform="uppercase"
					margin="6px 0"
				>
					Hakkında
				</Text>
				<Text font="--headline2" margin="0 0 12px 0">
					Hizmetlerimiz
				</Text>
				<Text font="--base">
				Kartepe ATV Safari'de, off-road deneyiminizi geliştirmek için tasarlanmış geniş bir hizmet yelpazesi sunmaktan gurur duyuyoruz. Misyonumuz, en kaliteli araçlardan uzman rehberlik ve desteğe kadar unutulmaz bir macera için ihtiyacınız olan her şeyi sağlamaktır. İster yalnız bir maceracı olun ister bir grubun parçası, hizmetlerimiz mümkün olan en iyi deneyimi yaşamanızı sağlamak için özel olarak hazırlanmıştır.
				</Text>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0">
			<Box display="grid" grid-template-columns="repeat(3, 1fr)" grid-gap="24px 4%" md-grid-template-columns="1fr">
				<Box justify-content="flex-start" flex-direction="column" display="flex">
					<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
					Filomuz
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						color="--greyD3"
						font="--base"
						lg-text-align="left"
						flex="1 0 auto"
					>
						Kartepe ATV Safari'nin bel kemiğini oluşturan filomuz, her sürücünün tercihlerine ve deneyim seviyesine uygun çeşitli ATV ve UTV'lerden oluşmaktadır. Her araç, parkurlarda en yüksek performansı ve güvenilirliği sağlamak için titizlikle korunmaktadır.
ATV'ler: Arazi araçlarımız heyecan ve macera arayan yalnız sürücüler için mükemmeldir.
     Yüksek Performans: Her ATV, optimum performans için güçlü motorlarla donatılmıştır.
     Güvenlik Özellikleri: ATV'lerimiz denge kontrolü ve sağlam süspansiyon sistemleri dahil olmak üzere gelişmiş güvenlik özelliklerine sahiptir.

					</Text>
				</Box>
				<Box justify-content="flex-start" flex-direction="column" display="flex">
					<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
					Güvenlik ve Destek 
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						color="--greyD3"
						font="--base"
						lg-text-align="left"
						flex="1 0 auto"
					>
						Kartepe ATV Safari'de güvenliğiniz bizim önceliğimizdir. Tüm müşterilerimiz için güvenli ve keyifli bir deneyim sağlamak için kapsamlı destek sunuyoruz.
Güvenlik Ekipmanı: Kasklar, eldivenler ve koruyucu giysiler dahil olmak üzere gerekli tüm güvenlik ekipmanlarını sağlıyoruz.
     Kalite Güvencesi: Ekipmanlarımız düzenli olarak denetlenir ve en yüksek standartlara göre bakımı yapılır.
     Uyum ve Rahatlık: Her sürücüye mükemmel uyum sağlamak için her ürün çeşitli boyutlarda mevcuttur.

					</Text>
				</Box>
				<Box justify-content="flex-start" flex-direction="column" display="flex">
					<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
					Özel Paketler
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						color="--greyD3"
						font="--base"
						lg-text-align="left"
						flex="1 0 auto"
					>
						Her maceranın benzersiz olduğunu biliyoruz. Bu nedenle özel ihtiyaçlarınıza uygun özelleştirilmiş kiralama paketleri sunuyoruz.
Esnek Kiralama Süreleri: Saatlikten tam günlük seçeneklere kadar çeşitli kiralama süreleri arasından seçim yapın.
     Kolaylık: Programınıza uyacak esnek teslim alma ve bırakma saatleri.
     Ekonomiklik: Bütçenize uygun rekabetçi fiyatlandırma seçenekleri.

					</Text>
				</Box>
			</Box>
		</Section>
		<Section padding="100px 40px 100px 40px" background="--color-light" sm-padding="40px 20px 40px 20px" quarkly-title="Images-22">
			<Override
				slot="SectionContent"
				flex-direction="row"
				grid-gap="20px"
				width="100%"
				max-width="none"
				lg-flex-direction="column"
				lg-align-items="flex-end"
				sm-min-width="280px"
			/>
			<Box
				min-width="100px"
				min-height="100px"
				width="60%"
				grid-gap="20px"
				display="flex"
				flex-direction="column"
				align-items="flex-end"
				sm-width="100%"
			>
				<Image src="https://novaverolip.com/images/7.jpg" display="block" width="100%" />
			</Box>
			<Box
				width="40%"
				lg-width="100%"
				lg-display="flex"
				lg-flex-direction="column"
				lg-align-items="flex-end"
			>
				<Text
					color="--darkL2"
					margin="100px 0px 50px 10px"
					font="--headline2"
					text-align="left"
					lg-width="100%"
					lg-margin="0px 0px 20px 0px"
					lg-font="--headline3"
					sm-margin="16px 0px 20px 0px"
					text-transform="uppercase"
					letter-spacing="2px"
				>
					Maceranıza Şimdi Başlayın!
				</Text>
				<Text
					color="--darkL2"
					margin="0px 0px 0px 0px"
					font="--lead"
					lg-max-width="540px"
					lg-width="100%"
				>
					Unutulmaz bir yolculuğa çıkmaya hazır mısınız? ATV veya UTV'nizi ayırtmak ve maceranıza başlamak için bugün Kartepe ATV Safari ile iletişime geçin. Heyecan, nefes kesici manzaralar ve kalıcı anılarla dolu bir deneyim vaat ediyoruz. Beklemeyin - bir sonraki harika maceranız sadece bir telefon uzağınızda!
				</Text>
			</Box>
		</Section>
		<Components.Contact />
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});